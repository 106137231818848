<template>
  <div></div>
</template>
<script setup>
import { getPreview } from "@/api/login/index";
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
const route = useRoute();
onMounted(() => {
    showContent()
});
const showContent = () => {
  getPreview(route.query.page).then((res) => {
    let data = res.data.replace("width:595.3pt;","");
    document.write(data);
  });
};
</script>
